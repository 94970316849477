import { LayoutFooterWrapper, RcaLogo, FooterLeft, FooterMiddle, FooterRight, SocialLink } from './styled'
import { useStoreon } from 'storeon/react'
import { terms, privacy } from 'constant'
import { Link } from 'react-router-dom'
import { Icon } from 'components'
import React from 'react'

const LayoutFooter = () => {
  const { dispatch } = useStoreon()

  return (
    <LayoutFooterWrapper>
      <div>
        <div>
          <FooterLeft>
            <RcaLogo />
          </FooterLeft>
          <FooterMiddle>
            <div>
              <Link to='/events'>Events</Link>
              <Link to='/artorganizations'>Arts Organizations</Link>
              <Link to='/somethingnew'>Explore Something New</Link>
              <Link to='/articles'>News</Link>
              <Link to='/episodes'>Episodes</Link>
              <Link to='/about'>About Us</Link>
              <Link to='/new-partner'>Partner With Us</Link>
            </div>
            <div>
              <a style={{ cursor: 'pointer' }} onClick={() => dispatch('setTextModal', terms)}>Terms of Service</a>
              <a style={{ cursor: 'pointer' }} onClick={() => dispatch('setTextModal', privacy)}>Privacy</a>
            </div>
          </FooterMiddle>
          <FooterRight>
            <div>Follow us on social media and join our growing community of performing arts enthusiasts.</div>
            <div>
              <SocialLink href='https://www.instagram.com/redcurtainaddict' target='_blank'>
                <Icon name='instagram' />
              </SocialLink>
              <SocialLink href='https://www.facebook.com/RedCurtainAddict' target='_blank'>
                <Icon name='facebook' />
              </SocialLink>
              <SocialLink href='https://www.youtube.com/channel/UCNi5rXYfCsHQMjE62LX3NNQ/videos' target='_blank'>
                <Icon name='youtube' />
              </SocialLink>
            </div>
          </FooterRight>
        </div>
        <p>© Red Curtain Addict 2025</p>
      </div>
    </LayoutFooterWrapper>
  )
}

export default LayoutFooter
